import React, {ReactElement} from 'react';

import {Grid} from '@mui/material';

interface Props {
  header: ReactElement | ReactElement[];
  icon?: ReactElement;
  content: ReactElement | ReactElement[];
  footer: ReactElement | ReactElement[];
}

export const CngResultPage = (props: Props) => {
  const { header, content, footer = null, icon = null } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        background: "#ffffff",
        padding: 16,
      }}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {
          icon !== null
            ? (
              <Grid item sx={{ fontSize: "3rem" }}>
                {icon}
              </Grid>
            )
            : null
        }
        <Grid item sx={(theme) => ({
          fontSize: "2rem",
          color: theme.palette.secondary.main
        })}>
          {header}
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        {content}
      </Grid>
      {
        footer !== null
          ? (
            <>{footer}</>

          )
          : null
      }
    </Grid>
  )
}
