import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

import {CngCheckbox} from '../../../shared/components/CngCheckbox';
import {RegistrationContext} from '../../../shared/context';
import {StepProps, ValidationHandle} from './Interfaces';

export const Privacy = forwardRef<ValidationHandle, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const { registration, updatePrivacy, updateNewsletter, updateDepOmgeving } = useContext(RegistrationContext);
  const [bdPrivacyInError, setBdPrivacyInError] = useState(false);
  const [bdDepInError, setDepInError] = useState(false);

  useImperativeHandle(ref, () => ({
    validate(): Promise<boolean> {
      setBdPrivacyInError(!registration.privacyAccepted);
      setDepInError(!registration.depOmgevingAccepted);
      return Promise.resolve(registration.privacyAccepted && registration.depOmgevingAccepted);
    },
  }));

  useEffect(() => {
    setBdPrivacyInError(false);
  }, [registration.privacyAccepted]);

  useEffect(() => {
    setDepInError(false);
  }, [registration.depOmgevingAccepted])

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('privacy.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('privacy.content1')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('privacy.content2')}
        </Typography>
      </Grid>
      <Grid item>
        <CngCheckbox
          checked={registration.privacyAccepted}
          handleChange={updatePrivacy}
          content={t('privacy.checkbox')}
          inError={bdPrivacyInError}
          error={t('privacy.checkbox-error')}
        />
      </Grid>
      <Grid item>
        <CngCheckbox
          checked={registration.depOmgevingAccepted}
          handleChange={updateDepOmgeving}
          content={t('privacy.checkbox-dep')}
          inError={bdDepInError}
          error={t('privacy.checkbox-dep-error')}
        />
      </Grid>
      <Grid item>
        <CngCheckbox
          checked={registration.newsletterAccepted}
          handleChange={updateNewsletter}
          content={t('privacy.checkbox-newsletter')}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('privacy.extra-content')} <a href="mailto:info@bodemleven.be ">info@bodemleven.be </a>
        </Typography>
      </Grid>
    </Grid>
  )
})
