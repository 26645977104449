import React, {forwardRef, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

import {StepProps, ValidationHandle} from './Interfaces';

export const TheeBag = forwardRef<ValidationHandle, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");

  useImperativeHandle(ref, () => ({
    validate(): Promise<boolean> {
      return Promise.resolve(true);
    },
  }));

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('theebag.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('theebag.content')}
        </Typography>
      </Grid>
    </Grid>
  )
})
