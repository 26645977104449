export const points = [
  "3290 Horta Diest - Tessenderloseweg 84",
  "3440 Horta Zoutleeuw, Lewastraat 2",
  "3500 Bruno service station Hasselt, Genkersteenweg 61",
  "3511 Horta Kuringen, Zolderse Kiezel 64",
  "3520 Bruno service station Zonhoven, Heuveneindeweg 72",
  "3520 Horta Zonhoven, Oppelsenweg 22",
  "3530 Bruno service station Houthalen, Grote Baan 6",
  "3591 Bruno service station Beverlo, Heppensesteenweg 91",
  "3600 Bruno service station Genk, Hasseltweg 15",
  "3600 Bruno service station Genk-noord, Hermeslaan 60",
  "3620 Horta Lanaken, Bedrijfsweg 6",
  "3630 Bruno service station Maasmechelen, Rijksweg 471",
  "3650 Horta Dilsen-Stokkem, Burgemeester Henrylaan 7",
  "3665 Bruno service station As, Steenweg 103",
  "3670 Bruno service station Oudsbergen, Genkerbaan 18",
  "3680 Bruno service station Maaseik, Maastrichtersteenweg 168",
  "3690 Bruno service station Zutendaal, Daalstraat 61",
  "3700 Bruno service station Tongeren, Luikersteenweg 216",
  "3700 Horta Tongeren, Luikersteenweg 40",
  "3720 Bruno service station Kortessem, Hasseltsesteenweg 25",
  "3740 Bruno service station Bilzen, Kruisbosstraat 2b1",
  "3800 Bruno service station Sint-Truiden, Naamsesteenweg 249",
  "3830 Horta Wellen, Dorpsstraat 18",
  "3930 Horta Hamont-Achel, Bosstraat 119",
  "3950 Bruno service station Bocholt, Kaulillerweg 161",
  "3960 Bruno service station Bree, Meeuwerkiezel 116",
  "3980 Bruno service station Tessenderlo, Hyundailaan 2",
  "3990 Bruno service station Peer, Baan naar Bree 138"
];
