import {createContext, useState} from 'react';

import {Confirmation} from '../../models/confirmation';
import {LivingEnvironment, Registration} from '../../models/registration';
import {checkEmail, fetchConfirmation, registerConfirmation, registerUser} from '../../services';

export interface RegistrationContextProps {
  registration: Registration;
  updatePrivacy: (value: boolean) => void;
  updateDepOmgeving: (value: boolean) => void;
  updateNewsletter: (value: boolean) => void;
  saveRegistration: (newReg: Registration) => Promise<string>;
  updateRegistration: (newReg: Registration) => void;
  confirmation: Confirmation;
  getConformation: (regNumber: string, check: string) => Promise<void>;
  setPickupPoint: (value: string) => void;
  setExtraValues: (type: string, age: string, property: string, maintenance: string, comment: string) => void;
  saveConfirmation: (newConfirmation: Confirmation) => Promise<string>;
}

const defaultRegistration: Registration = {
  bus: "",
  companyName: "",
  county: "",
  email: "",
  firstName: "",
  lastName: "",
  livingEnvironment: 0,
  locationSize: undefined,
  privacyAccepted: false,
  depOmgevingAccepted: false,
  newsletterAccepted: false,
  number: "",
  street: "",
  zipCode: "",
  registrationType: 0,
  theeBagLocation: 0
}

const defaultConfirmation: Confirmation = {
  regNumber: "",
  check: "",
  firstName: "",
  lastName: "",
  email: "", 
  address: "",
  gardenSize: 0,
  environment: 0,
  theeBagLocation: 0,
  pickupPoint: "",
  soilType: "",
  ageGarden: "",
  propertyType: "",
  maintenance: "",
  comment: ""
}

export const RegistrationContext = createContext<RegistrationContextProps>(null!);

export const useRegistrationContext = (): RegistrationContextProps => {
  const [registration, setRegistration] = useState<Registration>(defaultRegistration);
  const [confirmation, setConfirmation] = useState<Confirmation>(defaultConfirmation);

  const updatePrivacy = (value: boolean): void => {
    setRegistration((prev) => ({ ...prev, privacyAccepted: value }));
  }

  const updateNewsletter = (value: boolean): void => {
    setRegistration((prev) => ({ ...prev, newsletterAccepted: value }));
  }

  const updateDepOmgeving = (value: boolean): void => {
    setRegistration((prev) => ({ ...prev, depOmgevingAccepted: value }));
  }

  const saveRegistration = (newReg: Registration): Promise<string> => {
    setRegistration(newReg);
    return registerUser(newReg);
  }

  const checkIfEmailExists = (email: string): Promise<boolean | null> => {
    return checkEmail(email);
  }

  const updateRegistration = (newReg: Registration): void => {
    setRegistration(newReg);
  }

  const getConformation = async (regNumber: string, check: string): Promise<void> => {
    if (regNumber !== '' && check !== '') {
      var confirm = await fetchConfirmation(regNumber, check);
      confirm && setConfirmation(confirm);
    }
  }

  const setPickupPoint = (value: string): void => {
    setConfirmation((prev) => ({...prev, pickupPoint: value}));
  }

  const setExtraValues = (type: string, age: string, property: string, maintenance: string, comment: string): void => {
    setConfirmation((prev) => ({...prev, type: type, age: age, property: property, maintenance: maintenance, comment: comment}));
  }

  const saveConfirmation = (newConfirmation: Confirmation): Promise<string> => {
    setConfirmation(newConfirmation);
    return registerConfirmation(newConfirmation);
  }

  return {
    registration, updatePrivacy, saveRegistration, updateRegistration,
    updateNewsletter, updateDepOmgeving,
    confirmation, getConformation, setPickupPoint, setExtraValues, saveConfirmation
  };
};
