import React from 'react';

import {Box, Checkbox, Grid, Theme, Typography} from '@mui/material';

interface Props {
  checked: boolean;
  handleChange: (value: boolean) => void;
  content: string;
  inError?: boolean;
  error?: string | React.ReactElement | null;
}

export const CngCheckbox = (props: Props) => {
  const { checked, content = "", handleChange, inError = false, error = null } = props;

  const mappedContent = {__html: content};
  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <Box component="div" sx={{ width: '40px' }}>
        <Checkbox
          color="primary"
          sx={{ mt: -1.5 }}
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.checked)}
        />
      </Box>
      <div>
        <Grid container direction="column">
          <Grid item>
            <span dangerouslySetInnerHTML={mappedContent}></span>
          </Grid>
          {
            error !== null ?
              (
                inError ? (
                  <Grid item sx={(theme: Theme) => ({
                    height: '22px',
                    [theme.breakpoints.down('xs')]: { height: '50px', }
                  })}>
                    <Typography variant="subtitle2" color="red">
                      {error}
                    </Typography>
                  </Grid>
                ) : <Grid item sx={(theme) => ({
                  color: theme.palette.error.main,
                  fontSize: '0.8rem',
                  height: 22,
                  [theme.breakpoints.down('xs')]: {
                    height: 50,
                  }
                })}></Grid>
              )
              : null
          }
        </Grid>
      </div>
    </Box>
  )
}
