import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: window.runConfig.backend,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export { axiosInstance };
