import React from 'react';
import {useTranslation} from 'react-i18next';

import {Grid} from '@mui/material';

interface Props {

}

export const Footer = (props: Props) => {
  const { t } = useTranslation("registration");

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item sx={{ height: '40px' }}>
        <a href={window.runConfig.privacyLink} target="_blank" rel="noreferrer noopener">{t('privacy-footer')}</a>
      </Grid>
    </Grid>
  )
}
