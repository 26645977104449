import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

import {CngCheckbox} from '../../../shared/components/CngCheckbox';
import {StepProps, ValidationHandle} from './Interfaces';

export const Terms = forwardRef<ValidationHandle, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const [questions, setQuestions] = useState<boolean[]>([false, false, false, false, false, false, false, false])
  //const [questions, setQuestions] = useState<boolean[]>([true, true, true, true, true, true, true, true])
  const [inError, setInError] = useState(false);

  useImperativeHandle(ref, () => ({
    validate(): Promise<boolean> {
      setInError(questions.some((x) => !x));
      return Promise.resolve(!questions.some((x) => !x));
    },
  }));

  useEffect(() => {
    setInError(false);
  }, [questions]);

  const updateAnswer = (index: number, answer: boolean): void => {
    setQuestions((data) => data.map((item, i) =>
      i === index ? answer : item
    ));
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('terms.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('terms.content')}
        </Typography>
      </Grid>
      {
        questions.map((item, index) => {
          return (
            <Grid item key={index}>
              <CngCheckbox
                checked={item}
                handleChange={(x) => updateAnswer(index, x)}
                content={t(`terms.q${index + 1}`)}
              />
            </Grid>
          )
        })
      }
      <Grid item sx={{ minHeight: 4 }}>
        {
          inError && (
            <Typography variant="subtitle2" color="red">
              {t('terms.error')}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  )
})
