import React from 'react';

import {Box, Grid, useMediaQuery, useTheme} from '@mui/material';

import cmk from '../../assets/images/CMK.png';
import dep from '../../assets/images/dep.png';
import hbvl from '../../assets/images/HBVL.png';
import orange from '../../assets/images/Orange.jpg';

interface Props {
  alt: string;
  src: any;
}

const LogoBoxLarge = (props: Props) => {
  const { src, alt } = props;
  return (
    <Box component="img" src={src} alt={alt} sx={theme => ({
      height: '50px',
      ":not(:first-of-type)": {
        marginLeft: 2
      }
    })} />)
}

const LogoBoxMedium = (props: Props) => {
  const { src, alt } = props;
  return (
    <Box component="img" src={src} alt={alt} sx={theme => ({
      height: '50px',
      ":not(:first-of-type)": {
        marginLeft: 2
      },
      [theme.breakpoints.down('md')]: {
        height: '40px'
      }
    })} />)
}

export const LogoContainer = () => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container direction={mdDown ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <LogoBoxLarge src={cmk} alt="CMK UHasselt logo" />
        <LogoBoxLarge src={hbvl} alt="Het Belang van Limburg logo" />
      </Grid>
      <Grid item>
        <LogoBoxMedium src={dep} alt="Departement Omgeving logo" />
        <LogoBoxMedium src={orange} alt="Orange logo" />
      </Grid>
    </Grid>
  )
}
