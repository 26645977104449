import React, {forwardRef, useContext, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

import {RegistrationContext} from '../../../shared/context';
import {LivingEnvironment, TheeBagLocation} from '../../../shared/models/registration';
import {StepProps, ValidationHandle} from '../../Wizard/Steps';

type Props = {}

export const Intro = forwardRef<ValidationHandle, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const { confirmation } = useContext(RegistrationContext);
  useImperativeHandle(ref, () => ({
    validate(): Promise<boolean> {
      return Promise.resolve(true);
    },
  }));

  const getLivingEnvironment = (value: LivingEnvironment): string => {
    switch (value) {
      case LivingEnvironment.City: return "de stad";
      case LivingEnvironment.CityEdge: return "de stadsrand";
      case LivingEnvironment.Countryside: return "het buitengebied";
      default: return '';
    }
  }

  const getTheebagLocation = (value: TheeBagLocation): string => {
    console.log(value)
    switch (value) {
      case TheeBagLocation.GrassField: return "grasveld";
      case TheeBagLocation.Flowers: return "bloemenperk/kruidentuin";
      case TheeBagLocation.VegetableGarden: return "groententuin";
      case TheeBagLocation.Farmland: return "landbouwgrond/akker";
      default: return '';
    }
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('confirmed.intro.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.intro.body1')}
          <br /><br />
          {t('confirmed.intro.body2')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {t('confirmed.intro.info-title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.intro.body3')}
          {t('confirmed.intro.body4')} <a href="mailto:info@bodemleven.be">info@bodemleven.be</a> {t('confirmed.intro.body5')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>Voornaam: {confirmation.firstName}</Grid>
          <Grid item>Achternaam: {confirmation.lastName}</Grid>
          <Grid item>E-mailadres: {confirmation.email}</Grid>
          <Grid item>Registratie nummer: {confirmation.regNumber}</Grid>
          <Grid item>Adres van het meetpunt: {confirmation.address}</Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {t('confirmed.intro.garden-title')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>Mijn tuin is {confirmation.gardenSize}m²</Grid>
          <Grid item>Mijn tuin bevindt zich in {getLivingEnvironment(confirmation.environment)}</Grid>
          <Grid item>Ik wil mijn theezakjes ingraven in een {getTheebagLocation(confirmation.theeBagLocation)}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})