import React from 'react';
import {useParams} from 'react-router';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {Grid, Typography} from '@mui/material';

import {LogoContainer} from '../../shared/components/LogoContainer';

export const RegistrationConfirmed = (): React.ReactElement => {
  const { firstName } = useParams();

  return (
    <Grid item container direction="column" alignItems="center" spacing={6}>
      <Grid item>
        <CheckCircleRoundedIcon style={{ fontSize: 100 }} color="success" />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Beste {firstName} <br /><br />
          Bedankt om je kandidaat te stellen voor Bodemleven! Je ontvangt van ons een bevestiging van je registratie per mail. Heb je de bevestigingsmail niet ontvangen, controleer dan zeker je spam-folder. Heb je na een paar uur nog geen mail van ons ontvangen, neem dan contact met ons op via <a href="mailto:info@bodemleven.be?Subject=Vraag over inschrijving">info@bodemleven.be</a>. Je hoort op 19 april of jouw tuin bij de definitieve selectie zit.
        </Typography>
      </Grid>
      <Grid item>
        <LogoContainer />
      </Grid>
    </Grid>
  )
}
