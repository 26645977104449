import {format, isAfter, parse} from 'date-fns';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

import {LogoContainer} from '../../shared/components/LogoContainer';

interface Props {

}

const RegistrationClosed = (props: Props) => {
  const { t } = useTranslation('registration');
  const dateClosed = parse(window.runConfig.dateCloseRegistration, "yyyy-MM-dd HH:mm", new Date());
  const dateOpen = parse(window.runConfig.dateOpenRegistration, "yyyy-MM-dd HH:mm", new Date());

  return (
    <Grid container direction="column" spacing={4} justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="h5">
          {t('closed-title')}
        </Typography>
      </Grid>
      {
        isAfter(new Date(), dateClosed)
          ? (
            <Grid item>
              <Typography variant="body1">
                {t('closed-after')}<a href={window.runConfig.newsletterLink} target="_blank" rel="noreferrer noopener">nieuwsbrief</a>.
              </Typography>
            </Grid>
          )
          : (
            <Grid item>
              <Typography variant="body1">
                {t('closed-before', { start: format(dateOpen, "dd/MM/yyy") })}<a href={window.runConfig.newsletterLink} target="_blank" rel="noreferrer noopener">nieuwsbrief</a>.
              </Typography>
            </Grid>
          )
      }
      <LogoContainer />
    </Grid>
  )
}

export default RegistrationClosed
