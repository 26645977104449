import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent,
  Typography,
} from '@mui/material';

import {RegistrationContext} from '../../../shared/context';
import {StepProps, ValidationHandleWithGoBackSave} from '../../Wizard/Steps';
import {points} from './pickup-points';

type Props = {}

export const Pickup = forwardRef<ValidationHandleWithGoBackSave, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const [point, setPoint] = useState('');
  const [inError, setInError] = useState(false);

  const { confirmation, setPickupPoint } = useContext(RegistrationContext);

  useEffect(() => {

    setPoint(confirmation.pickupPoint ?? '');
  }, [confirmation.pickupPoint]);


  useImperativeHandle(ref, () => ({
    validate(): Promise<boolean> {
      var value = point === '';
      setInError(value);
      return Promise.resolve(!value);
    },
    saveChanges(): void {
      setPickupPoint(point);
    }
  }));

  const handleChange = (event: SelectChangeEvent) => {
    var value = event.target.value as string;
    setInError(value === '');
    setPoint(value);
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('confirmed.pickup.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.pickup.body1')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.pickup.body2')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.pickup.body3')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={12} md={9} lg={6}>
            <FormControl sx={{ mt: 1, minWidth: 200, maxWidth: "calc(100vw - 40px)" }} fullWidth size="small" error={inError}>
              <InputLabel id="pickup-select-label">Ophaalpunt</InputLabel>
              <Select
                labelId="pickup-select-label"
                id="demo-simple-select"
                value={point}
                label="Ophaalpunt"
                onChange={handleChange}
              >
                {points.map(x =>
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>)}
              </Select>
              {inError && <FormHelperText>Selecteer een ophaalpunt</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})