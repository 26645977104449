import './index.css';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {ThemeProvider} from '@mui/material';

import App from './App';
import {initI18n} from './i18n';
import reportWebVitals from './reportWebVitals';
import {CngPageLoader} from './shared/components/CngPageLoader';
import CnTheme from './shared/theme';
import {Config} from './shared/utils/CustomWindow';

declare global {
  interface Window {
    runConfig: Config,
  }
}

initI18n([]);

ReactDOM.render(
    <Suspense fallback={<CngPageLoader />}>
      <ThemeProvider theme={CnTheme}>
          <div className='notranslate'>
            <App />
          </div>
      </ThemeProvider>
    </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
