import {Confirmation} from '../models/confirmation';
import {Registration} from '../models/registration';
import {axiosInstance} from './axiosInstance';

export const registerUser = (registration: Registration): Promise<string> => {
  return axiosInstance
    .post<string>(`register`, { ...registration, tenantId: window.runConfig.tenantId, projectId: 2 })
    .then((response) => {
      return response.data;
    });
};

export const checkEmail = (email: string): Promise<boolean | null> => {
  return axiosInstance
    .get<boolean>('email/exists', { params: { email, tenantId: window.runConfig.tenantId, projectId: 2 } })
    .then(response => response.data)
    .catch(() => null);
};

export const fetchConfirmation = (regNumber: string, check: string): Promise<Confirmation | null> => {
  return axiosInstance
    .get<Confirmation>('confirm', { params: { regNumber, check, tenantId: window.runConfig.tenantId } })
    .then(response => response.data)
    .catch(() => null);
};

export const registerConfirmation = (confirmation: Confirmation): Promise<string> => {
  return axiosInstance
    .post<string>(`confirm`, { 
      soilType: confirmation.soilType,
      ageGarden: confirmation.ageGarden,
      propertyType: confirmation.propertyType,
      maintenance: confirmation.maintenance,
      comment: confirmation.comment, 
      pickupPoint: confirmation.pickupPoint,
      tenantId: window.runConfig.tenantId, 
      projectId: 2,
      check: confirmation.check,
      regNumber: confirmation.regNumber
    })
    .then((response) => {
      return response.data;
    });
}
