import React from 'react';
import {useTranslation} from 'react-i18next';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Grid, useTheme} from '@mui/material';

import {CngResultPage} from '../../shared/components/CngResultPage';

export const NotFound = () => {
  const { t } = useTranslation("registration");
  const theme = useTheme();

  return (
    <CngResultPage
      icon={<CloseRoundedIcon color="error" sx={{fontSize: "5rem"}} />}
      header={<span style={{color: theme.palette.error.main}}>{t('page-not-found')}</span>}
      content={
        <></>
      }
      footer={
        <Grid item container justifyContent="flex-end" sx={{mt: 3, mb: 3, pl: 2, pr: 2}}>
          <Grid item>
            <a href="https://bodemleven.be" target="_blank" rel="noreferrer noopener">{t('navigate-away')}</a>
          </Grid>
        </Grid>
      }
    />
  )
}
