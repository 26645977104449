import {isAfter, isBefore, parse} from 'date-fns';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router';

import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {
  Button, CircularProgress, Grid, MobileStepper, useMediaQuery, useTheme,
} from '@mui/material';

import {RegistrationContext, RegistrationContextProvider} from '../../shared/context';
import {Intro, PersonalInfo, Privacy, StepProps, Terms, TheeBag} from './Steps';

export const Wizard = (props: StepProps): React.ReactElement => {
  const { action } = useParams();
  const { registration } = useContext(RegistrationContext);
  const { t } = useTranslation('registration');
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dateOpen = parse(window.runConfig.dateOpenRegistration, "yyyy-MM-dd HH:mm", new Date());
  const dateClosed = parse(window.runConfig.dateCloseRegistration, "yyyy-MM-dd HH:mm", new Date());
  const [disableControls, setDisableControls] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = 5;

  if (isBefore(new Date(), dateOpen) || isAfter(new Date(), dateClosed)) {
    navigate('/');
  }

  useEffect(() => {
    if (action === 'retry' && registration.privacyAccepted) {
      setActiveStep(5);
    }
    else {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const introRef = useRef<React.ElementRef<typeof Intro>>(null);
  const theeBagRef = useRef<React.ElementRef<typeof TheeBag>>(null);
  const privacyRef = useRef<React.ElementRef<typeof Privacy>>(null);
  const termsRef = useRef<React.ElementRef<typeof Terms>>(null);
  const personalInfoRef = useRef<React.ElementRef<typeof PersonalInfo>>(null);

  const handleNext = async () => {
    setDisableControls(true);

    let validated = true;
    if (activeStep === 0) {
      validated = (await introRef.current?.validate()) ?? false;
    }

    if (activeStep === 1) {
      validated = (await theeBagRef.current?.validate()) ?? false;
    }

    if (activeStep === 2) {
      validated = (await privacyRef.current?.validate()) ?? false;
    }

    if (activeStep === 3) {
      validated = (await termsRef.current?.validate()) ?? false;
    }

    if (activeStep === 4) {
      validated = (await personalInfoRef.current?.validate()) ?? false;

      if (validated) {
        await personalInfoRef.current?.saveChanges();
      }
    }

    setDisableControls(false);

    if (validated) {
      window.scrollTo(0, 0);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    setDisableControls(true);

    if (activeStep === 0) {
      setDisableControls(false);
      window.scrollTo(0, 0);
      navigate('/registration');
      return;
    }

    if (activeStep === 4) {
      window.scrollTo(0, 0);
      await personalInfoRef.current?.saveChanges();
    }

    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDisableControls(false);
  };

  return (
    <Grid item>
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{ minHeight: '500px' }}>
          <Grid container>
            {activeStep === 0 ? <Intro ref={introRef} /> : null}
            {activeStep === 1 ? <TheeBag ref={theeBagRef} /> : null}
            {activeStep === 2 ? <Privacy ref={privacyRef} /> : null}
            {activeStep === 3 ? <Terms ref={termsRef} /> : null}
            {activeStep === 4 ? <PersonalInfo ref={personalInfoRef} /> : null}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <MobileStepper
              steps={steps}
              position="static"
              variant={mdDown ? 'text' : 'dots'}
              activeStep={activeStep}
              sx={{ flexGrow: 1 }}
              nextButton={
                <Button
                  size="small"
                  color="secondary"
                  onClick={handleNext}
                  variant="contained"
                  disabled={disableControls}
                >
                  {
                    disableControls
                      ? (
                        <CircularProgress size={24} />
                      )
                      : (
                        <>
                          <span>{activeStep !== 4 ? t('next') : t('finish')}</span>
                          <KeyboardArrowRight />
                        </>
                      )
                  }
                </Button>
              }
              backButton={
                <Button size="small" color="secondary" onClick={handleBack} disabled={disableControls}>
                  <KeyboardArrowLeft />
                  {t('back')}
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const WizardContainer = (props: StepProps) => {
  return (
    <RegistrationContextProvider >
      <Wizard {...props} />
    </RegistrationContextProvider>
  )
}
