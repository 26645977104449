import React from 'react';
import {useParams} from 'react-router';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {Grid, Typography} from '@mui/material';

import {LogoContainer} from '../../shared/components/LogoContainer';

type Props = {}

export const ConfirmEnd = (props: Props) => {
  const { firstName } = useParams();

  return (
    <Grid item container direction="column" alignItems="center" spacing={6}>
      <Grid item>
        <CheckCircleRoundedIcon style={{ fontSize: 100 }} color="success" />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Beste {firstName} <br /><br />
          Bedankt voor het bevestigen van je deelname. Je bent nu officieel burgerwetenschapper! We sturen je per mail een overzicht van dit formulier, waaronder het door jou gekozen ophaalpunt voor je meetpakket.<br/><br/>
          De komende periode gaan we aan de slag met het samenstellen van het meetpakket. We houden je per mail op de hoogte van de vorderingen van het onderzoek.<br/><br/>
          Niks missen ? Volg ons ook op <a href="https://www.facebook.com/Bodemleven">Facebook</a>, <a href="https://www.instagram.com/bodem.leven/">Instagram</a> en <a href="https://twitter.com/bodemleven">Twitter</a> en lees mee in <a href="https://www.hbvl.be/bodemleven">Het Belang van Limburg</a>.
        </Typography>
      </Grid>
      <Grid item>
        <LogoContainer />
      </Grid>
    </Grid>
  )
}
