import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {Grid} from '@mui/material';

import {ConfirmContainer, ConfirmEnd} from './pages/Confirm';
import {NotFound} from './pages/NotFound';
import Registration from './pages/Registration/Registration';
import {RegistrationConfirmed} from './pages/Registration/RegistrationConfirmed';
import {WizardContainer} from './pages/Wizard';
import {Footer} from './shared/components/Footer';
import Header from './shared/components/Header/Header';
import {ScrollToTop} from './shared/components/ScrollToTop';

function App() {
  const { t } = useTranslation('home');

  return (
    <>
      <Helmet>
        <title>Bodemleven</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Grid container direction="column" justifyContent="space-between" sx={{minHeight: '100vh'}}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Header />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={0} md={2} lg={3}></Grid>
                <Grid item xs={12} md={8} lg={6} sx={{ p: 2 }}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <BrowserRouter>
                      <ScrollToTop />
                      <Routes>
                        <Route path="/" element={<Registration />} />
                        <Route path="/registration" element={<Registration />} />
                        <Route path="/registration/done/:firstName" element={<RegistrationConfirmed />} />
                        <Route path="/wizard" element={<WizardContainer />} />
                        <Route path="/wizard/:action" element={<WizardContainer />} />
                        <Route path="/confirm/:regNumber/:check" element={<ConfirmContainer/>} />
                        <Route path="/confirm/:action/:regNumber/:check" element={<ConfirmContainer/>} />
                        <Route path="/confirm/done/:firstName" element={<ConfirmEnd/>} />
                        <Route path="/notfound" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </BrowserRouter>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item >
          <Grid container>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default App;
