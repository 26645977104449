import './cngPageLoader.css';

import React from 'react';

import Grid from '@mui/material/Grid';

export function CngPageLoader() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid item>
        <span className="loader"></span>
      </Grid>
    </Grid>
  );
}
