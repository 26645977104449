import React, {
  Dispatch, forwardRef, SetStateAction, useContext, useEffect, useImperativeHandle, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {
  FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, StepProps,
  TextField, Typography,
} from '@mui/material';

import {RegistrationContext} from '../../../shared/context';
import {ValidationHandleWithGoBackSave} from '../../Wizard/Steps';

interface Option {
  label: string;
  value: number;
}

interface RadioGroupBlProps {
  title: string;
  body: string;
  options: Option[];
  value: string | null;
  setValue: Dispatch<SetStateAction<string | null>>;
  inError: boolean;
  disabled: boolean;
}

const RadioGroupBl = (props: RadioGroupBlProps) => {
  const { title, body, options, value, setValue, inError, disabled } = props;
  const { t } = useTranslation("registration");

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h6">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item>
        <FormControl error={inError} disabled={disabled}>
          <FormLabel >{t(body)}</FormLabel>
          <RadioGroup
            name="radio-type"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue((event.target as HTMLInputElement).value)}
          >
            {
              options.map((x) => <FormControlLabel value={x.value} control={<Radio size="small" />} label={x.label} key={x.value} />)
            }
          </RadioGroup>
          <FormHelperText>{inError && "Veld is verplicht"}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}



type Props = {}

export const Extra = forwardRef<ValidationHandleWithGoBackSave, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const navigate = useNavigate();

  const [type, setType] = useState<string | null>(null);
  const [typeError, setTypeError] = useState(false);
  const [age, setAge] = useState<string | null>(null);
  const [ageError, setAgeError] = useState(false);
  const [property, setProperty] = useState<string | null>(null);
  const [propertyError, setPropertyError] = useState(false);
  const [main, setMain] = useState<string | null>(null);
  const [mainError, setMainError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [inError, setInError] = useState(false);
  const [comment, setComment] = useState('');

  const { confirmation, setExtraValues, saveConfirmation } = useContext(RegistrationContext);

  useEffect(() => {
    setType(confirmation.soilType);
  }, [confirmation.soilType]);

  useEffect(() => {
    setAge(confirmation.ageGarden);
  }, [confirmation.ageGarden]);

  useEffect(() => {
    setProperty(confirmation.propertyType);
  }, [confirmation.propertyType]);

  useEffect(() => {
    setMain(confirmation.maintenance);
  }, [confirmation.maintenance]);

  useImperativeHandle(ref, () => ({
    async validate(): Promise<boolean> {
      setInError(false);
      setTypeError(type === null);
      setAgeError(age === null);
      setPropertyError(property === null);
      setMainError(main === null);

      const result = type === null || age === null || property === null || main === null;
      if (!result) {
        try {
          setSaving(true);
          await saveConfirmation({
            ...confirmation,
            soilType: type,
            ageGarden: age,
            propertyType: property,
            maintenance: main,
            comment: comment
          });

          navigate(`/confirm/done/${confirmation.firstName}`);
        }
        catch (err) {
          setSaving(false);
          setInError(true);
          return false;
        }
      }
      return Promise.resolve(!result);
    },
    saveChanges(): void {
      const internalType = type === null ? "" : type;
      const internalAge = age === null ? "" : age;
      const internalProperty = property === null ? "" : property;
      const internalMain = main === null ? "" : main;
      setExtraValues(internalType, internalAge, internalProperty, internalMain, comment);
    }
  }));

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h5">
          {t('confirmed.extra.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('confirmed.extra.body1')}
        </Typography>
      </Grid>
      <Grid item>
        <RadioGroupBl
          title="confirmed.extra.type-title"
          body="confirmed.extra.type-question"
          value={type}
          setValue={setType}
          inError={typeError}
          disabled={saving}
          options={
            [
              { label: "De bodem werd opgehoogd met aangevoerde grond", value: 0 },
              { label: "De bodem is de originele grond", value: 1 },
              { label: "Ik weet het niet", value: 2 },
            ]
          }
        />
      </Grid>
      <Grid item>
        <RadioGroupBl
          title="confirmed.extra.age-title"
          body="confirmed.extra.age-question"
          value={age}
          setValue={setAge}
          inError={ageError}
          disabled={saving}
          options={
            [
              { label: "Vorig jaar", value: 0 },
              { label: "2 tot 5 jaar geleden", value: 1 },
              { label: "5 tot 10 jaar geleden", value: 2 },
              { label: "Meer dan 10 jaar geleden", value: 3 },
              { label: "Weet ik niet", value: 4 },
            ]
          }
        />
      </Grid>
      <Grid item>
        <RadioGroupBl
          title="confirmed.extra.property-title"
          body="confirmed.extra.property-question"
          value={property}
          setValue={setProperty}
          inError={propertyError}
          disabled={saving}
          options={
            [
              { label: "Ik heb een strakkere, moderne tuin die grondig onderhouden wordt", value: 0 },
              { label: "Ik heb een onderhoudsvriendelijke tuin met vooral gazon", value: 1 },
              { label: "Ik heb een biodiverse tuin die grondig onderhouden wordt", value: 2 },
              { label: "Ik heb een biodiverse tuin die beperkt onderhouden wordt", value: 3 },
              { label: "Ik heb een wilde tuin met natuurlijke beplanting en zaailingen die zeer beperkt onderhouden wordt", value: 4 },
            ]
          }
        />
      </Grid>
      <Grid item>
        <RadioGroupBl
          title="confirmed.extra.main-title"
          body="confirmed.extra.main-question"
          value={main}
          setValue={setMain}
          inError={mainError}
          disabled={saving}
          options={
            [
              { label: "Ik heb groene vingers en onderhoud mijn tuin met grote zorg (dagelijks of meerdere keren per week mee bezig)", value: 0 },
              { label: "Ik heb groene vingers en doe een behoorlijk basisonderhoud (paar keer per week)", value: 1 },
              { label: "Ik doe een beperkt onderhoud (een keer per week tot een paar keer per maand)", value: 2 },
              { label: "Ik laat vooral de natuur haar gang gaan (groot onderhoud een paar keer per jaar)", value: 3 },
              { label: "Ik besteed het onderhoud uit (vb snoeiwerken of bemesting, paar keer per jaar)", value: 4 },
              { label: "Geen onderhoud", value: 5 },
            ]
          }
        />
      </Grid>
      <Grid item>
        <TextField
          label={t('confirmed.extra.comment')}
          defaultValue={''}
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          disabled={saving}
          helperText={t('confirmed.extra.comment-helper')}
          onChange={(event: any) => {setComment(event.target.value)}}
        />
      </Grid>
      {
        inError && (
          <Grid item>
            <Grid container justifyContent="right">
              <Grid item>
                <Typography variant="body1" color="error">
                  Er liep iets mis met het opslaan van uw gegevens. Probeer het nog eens.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  )
})
