import * as EmailValidator from 'email-validator';
import React, {forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material';

import {CngInput} from '../../../shared/components/CngInput';
import {RegistrationContext} from '../../../shared/context';
import {
  LivingEnvironment, RegistrationType, TheeBagLocation,
} from '../../../shared/models/registration';
import {StepProps, ValidationHandleWithGoBackSave} from './Interfaces';

export const PersonalInfo = forwardRef<ValidationHandleWithGoBackSave, StepProps>((props, ref) => {
  const { t } = useTranslation("registration");
  const [saving, setSaving] = useState(false);
  const [inError, setInError] = useState(false);
  const navigate = useNavigate();
  const { registration, updateRegistration, saveRegistration } = useContext(RegistrationContext);

  useImperativeHandle(ref, () => ({
    async validate(): Promise<boolean> {
      setInError(false);
      var result = await trigger();
      if (result) {
        const values = getValues();
        setSaving(true);
        try {
          await saveRegistration({
            ...registration,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            registrationType: values.type,
            street: values.street,
            number: values.number,
            bus: values.bus,
            zipCode: values.zipCode,
            county: values.county,
            livingEnvironment: values.livingEnvironment,
            theeBagLocation: values.theeBagLocation,
            locationSize: values.locationSize,
            companyName: values.companyName
          });

          navigate(`/registration/done/${values.firstName}`);
        }
        catch (err) {
          setSaving(false);
          setInError(true);
          return false;
        }
      }

      return result;
    },
    saveChanges(): void {
      const values = getValues();
      updateRegistration({
        ...registration,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        registrationType: values.type,
        street: values.street,
        number: values.number,
        zipCode: values.zipCode,
        county: values.county,
        livingEnvironment: values.livingEnvironment,
        theeBagLocation: values.theeBagLocation,
        locationSize: values.locationSize,
        companyName: values.companyName,
        bus: values.bus
      });
    }
  }));

  const { register, trigger, getValues, control, formState: { errors } } = useForm({
    mode: "all",
    defaultValues: {
      firstName: registration.firstName,
      lastName: registration.lastName,
      email: registration.email,
      type: RegistrationType.Private,
      street: registration.street,
      number: registration.number,
      bus: registration.bus,
      zipCode: registration.zipCode,
      county: registration.county,
      livingEnvironment: registration.livingEnvironment,
      theeBagLocation: registration.theeBagLocation,
      locationSize: registration.locationSize,
      companyName: registration.companyName
    },
  });

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          {t('personal-info.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('personal-info.content')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {t('personal-info.details')}
        </Typography>
      </Grid>
      <Grid item>
        <FormControl size="small">
          <InputLabel id="select-type">{t('personal-info.type')}</InputLabel>
          <Controller
            name="type"
            defaultValue={registration.registrationType}
            control={control}
            render={({ field }) => (
              <Select
                labelId="select-type"
                {...field}
                sx={{ minWidth: '175px' }}
                label={t('personal-info.type')}
              >
                <MenuItem value={RegistrationType.Private}>Privé persoon</MenuItem>
                <MenuItem value={RegistrationType.School}>School</MenuItem>
                <MenuItem value={RegistrationType.SamenTuiner}>SamenTuiner</MenuItem>
                <MenuItem value={RegistrationType.Company}>Bedrijf</MenuItem>
                <MenuItem value={RegistrationType.Farmer}>Landbouwer</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CngInput
              error={errors.firstName}
              inputRef={register("firstName", { required: true })}
              defaultValue={registration.firstName}
              disabled={saving}
              fieldName="firstName"
              errorText={{ required: t('personal-info.first-name-required') }}
              label={t("personal-info.first-name")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CngInput
              error={errors.lastName}
              inputRef={register('lastName', { required: true })}
              defaultValue={getValues('lastName')}
              disabled={saving}
              fieldName="lastName"
              errorText={{ required: t('personal-info.last-name-required') }}
              label={t("personal-info.last-name")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CngInput
              error={errors.email}
              inputRef={register("email", { required: true, validate: (value) => EmailValidator.validate(value) })}
              defaultValue={getValues('email')}
              disabled={saving}
              fieldName="email"
              type="email"
              helperText={t('personal-info.email-helper') ?? ''}
              errorText={{
                required: t('personal-info.email-required'),
                validate: t('personal-info.email-not-valid')
              }}
              label={t("personal-info.email")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CngInput
              error={errors.companyName}
              inputRef={register("companyName")}
              defaultValue={registration.companyName}
              disabled={saving}
              fieldName="companyName"
              label={t("personal-info.company-name")}
              helperText={t("personal-info.company-name-helper") ?? ""}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {t('personal-info.address')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CngInput
              error={errors.street}
              inputRef={register('street', { required: true })}
              defaultValue={getValues('street')}
              disabled={saving}
              fieldName="street"
              errorText={{ required: t('personal-info.street-required') }}
              label={t("personal-info.street")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CngInput
              error={errors.number}
              inputRef={register('number', { required: true })}
              defaultValue={getValues('number')}
              disabled={saving}
              fieldName="number"
              errorText={{ required: t('personal-info.number-required') }}
              label={t("personal-info.number")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CngInput
              inputRef={register('bus', { required: false })}
              defaultValue={getValues('bus')}
              fieldName="bus"
              label={t("personal-info.bus")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CngInput
              error={errors.zipCode}
              inputRef={register('zipCode', { required: true, pattern: /[0-9]{4}/ })}
              defaultValue={getValues('zipCode')}
              disabled={saving}
              type="number"
              fieldName="zipCode"
              errorText={{
                required: t('personal-info.zip-code-required'),
                pattern: t('personal-info.zip-code-format')
              }}
              label={t("personal-info.zip-code")}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CngInput
              error={errors.county}
              inputRef={register('county', { required: true })}
              defaultValue={getValues('county')}
              disabled={saving}
              fieldName="county"
              errorText={{ required: t('personal-info.county-required') }}
              label={t("personal-info.county")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {t('personal-info.garden')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CngInput
              type="number"
              error={errors.locationSize}
              inputRef={register('locationSize', { required: true })}
              defaultValue={getValues('locationSize')}
              fieldName="locationSize"
              disabled={saving}
              helperText={t('personal-info.location-size-help') ?? ""}
              errorText={{ required: t('personal-info.location-size-required') }}
              label={t("personal-info.location-size")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="select-garden-type">{t('personal-info.garden-type')}</InputLabel>
              <Controller
                name='livingEnvironment'
                defaultValue={registration.livingEnvironment}
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="select-garden-type"
                    disabled={saving}
                    {...field}
                    label={t('personal-info.garden-type')}
                  >
                    <MenuItem value={LivingEnvironment.City}>In de stad</MenuItem>
                    <MenuItem value={LivingEnvironment.CityEdge}>In de stadsrand</MenuItem>
                    <MenuItem value={LivingEnvironment.Countryside}>In het buitengebied</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="select-thee-bag-location">{t('personal-info.thee-bag-location')}</InputLabel>
              <Controller
                name="theeBagLocation"
                defaultValue={registration.theeBagLocation}
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="select-thee-bag-location"
                    {...field}
                    disabled={saving}
                    label={t('personal-info.thee-bag-location')}
                  >
                    <MenuItem value={TheeBagLocation.GrassField}>Grasveld</MenuItem>
                    <MenuItem value={TheeBagLocation.Flowers}>Bloemenperk/kruidentuin</MenuItem>
                    <MenuItem value={TheeBagLocation.VegetableGarden}>Groententuin</MenuItem>
                    <MenuItem value={TheeBagLocation.Farmland}>Landbouwgrond/akker</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {
        inError && (
          <Grid item>
            <Grid container justifyContent="right">
              <Grid item>
                <Typography variant="body1" color="error">
                  Er liep iets mis met het opslaan van uw gegevens. Probeer het nog eens.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  )
})
