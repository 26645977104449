import React from 'react';
import {FieldError} from 'react-hook-form';

import {FormControl, InputLabel, TextField} from '@mui/material';

interface Props {
  error?: FieldError | undefined;
  inputRef: any;
  defaultValue: string | number | undefined;
  fieldName: string;
  errorText?: any;
  label?: string | null;
  type?: 'text' | 'number' | 'email';
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  endAdornment?: React.ReactElement
}

export const CngInput = (props: Props) => {
  const {
    error,
    inputRef,
    defaultValue,
    fieldName,
    errorText,
    label = "",
    type = 'text',
    onChange,
    disabled = false,
    className = "",
    helperText = ""
  } = props;

  const {ref, ...rest} = inputRef;

  return (
    <FormControl fullWidth className={className}>
      <TextField
        label={label}
        defaultValue={defaultValue}
        inputRef={ref}
        {...rest}
        variant="outlined"
        error={!!error}
        size="small"
        name={fieldName}
        color="primary"
        fullWidth
        type={type}
        disabled={disabled}
        helperText={error ? errorText[error.type] : helperText}
        onChange={(event: any) => {
          if (!!onChange) {
            onChange(event.target.value as string)
          }
        }}
      />
    </FormControl>
  )
}