import React from 'react';
import {useTranslation} from 'react-i18next';

import {CircularProgress, Grid} from '@mui/material';

export const CngLoading = () => {
  const { t } = useTranslation("registration");

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: '128px', p: 4 }}>
      <Grid item>
        <CircularProgress color="secondary" size={80} />
      </Grid>
      <Grid item className="m-t-16">
        {t('loading')}
      </Grid>
    </Grid>
  )
}
