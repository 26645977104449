import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

let translationFilePath = '/locales/nl/{{ns}}.json';

const namespaces = [
  'registration'
];


i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next);

export const initI18n = (caches: string[]): void => {
  i18n.init({
    fallbackLng: 'nl',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    ns: namespaces,
    detection: {
      order: ['cookie', 'querystring', 'path', 'navigator'],
      caches,
      cookieMinutes: 160,
      lookupFromPathIndex: 0,
    },
    backend: {
      loadPath: translationFilePath,
    },
  })
};

export const getLanguage = (): string =>
i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'nl';

export default i18n;