import {createTheme, ThemeOptions} from '@mui/material';

const overrides: ThemeOptions = {
  palette: {
    primary: {
      light: "#fee9b3",
      main: "#fab700",
      dark: "#f89f00",
      contrastText: "#000000",
    },
    secondary: {
      light: "#fbddbc",
      main: "#f18c21",
      dark: "#eb6f14",
      contrastText: "#000000",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: "#ffffff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export default createTheme(overrides);
