import React, {ReactElement} from 'react';

import {RegistrationContext, useRegistrationContext} from './RegistrationState';

interface Props {
  children: ReactElement | ReactElement[];
}

export function RegistrationContextProvider(props: Props): ReactElement {
  const {children} = props;
  const registrationContext = useRegistrationContext();

  return (
    <RegistrationContext.Provider value={registrationContext}>
      {children}
    </RegistrationContext.Provider>
  );
}
