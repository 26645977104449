import React from 'react';

import {Grid} from '@mui/material';

import logo from '../../assets/images/logo.png';

const Header = () => {

  return (
    <Grid item container justifyContent="center" sx={{mt: 2}}>
      <img src={logo} alt="logo" height="100" />
    </Grid>
  )
}

export default Header
